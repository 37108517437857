<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <!-- Stats Card Horizontal -->
      <b-row>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-horizontal
            icon="CpuIcon"
            :statistic="statistic.totalMoneyTrade.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }) || 0"
            statistic-title="Tổng tiền giao dịch"
          />
        </b-col>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-horizontal
            icon="ServerIcon"
            color="success"
            :statistic="statistic.totalMoneyTradeSuccess.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }) || 0"
            statistic-title="Tổng tiền thành công"
          />
        </b-col>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-horizontal
            icon="ActivityIcon"
            color="danger"
            :statistic="statistic.totalMoneyTradeFail.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }) || 0"
            statistic-title="Tổng tiền thất bại"
          />
        </b-col>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-horizontal
            icon="AlertOctagonIcon"
            color="warning"

            :statistic="statistic.totalCountTrade"
            statistic-title="Tổng số giao dịch"
          />
        </b-col>
      </b-row>
      <b-card>
        <b-row>
          <b-col cols="auto">
            <b-form-radio-group
              v-model="itemView"
              class="list item-view-radio-group"
              buttons
              size="sm"
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
                <feather-icon
                  :icon="option.icon"
                  size="18"
                />
              </b-form-radio>
            </b-form-radio-group>
          </b-col>

          <b-col>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchKeyWord"
                placeholder="Tìm kiếm"
              />
            </b-input-group>
          </b-col>

          <b-col>
            <b-form-datepicker
              v-model="dateFrom"
              local="vn"
              placeholder="Từ ngày"
              selected-variant="primary"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              style="margin-bottom: 0.2rem;"
            />
            <b-form-timepicker
              v-model="timeFrom"
              :disabled="dateFrom === null"
              locale="en"
              placeholder="From"
            />
          </b-col>

          <b-col>
            <b-form-datepicker
              v-model="dateTo"
              local="vn"
              placeholder="Đến ngày"
              selected-variant="primary"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              style="margin-bottom: 0.2rem;"
            />
            <b-form-timepicker
              v-model="timeTo"
              :disabled="dateTo === null"
              locale="en"
              placeholder="To"
            />
          </b-col>

          <b-col>
            <b-form-group>
              <v-select
                v-model="merchantName"
                placeholder="Tên merchant"
                label="username"
                :options="merchantNameOptions"
                :reduce="merchant => merchant.id"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group>
              <v-select
                v-model="payGate"
                placeholder="Cổng TT"
                label="gate"
                :options="payGateOptions"
                :reduce="gate => gate.id"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group>
              <v-select
                v-model="orderStatus"
                placeholder="Trạng thái"
                label="text"
                :options="orderStatusOptions"
                :reduce="status => status.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          align-h="end"
          class="mb-2"
        >
          <b-col>
            <b-button
              variant="primary"
              class="float-right"
              :disabled="isTableLoading"
              @click="fetchOrderPayout(true)"
            >
              Tìm kiếm
            </b-button>
            <b-button
              variant="outline-info"
              class="mr-1 float-right"
              :disabled="isLoading || isTableLoading"
              @click="exportExcel"
            >
              <feather-icon
                icon="DownloadIcon"
              />
              {{ $t('Xuất Excel') }}
            </b-button>
          </b-col>
        </b-row>
        <b-table
          small
          :fields="fields"
          :items="items"
          :busy="isTableLoading"
          responsive
          striped
          show-empty
        >
          <template #empty>
            <b-row>
              <b-col>
                <b-alert
                  show
                  variant="info"
                  class="text-center"
                >
                  Không có dữ liệu
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- A virtual column -->
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(transactionId)="data">
            <span
              class="text-nowrap transaction-item"
              @click="payoutDetailModal.open(data.item)"
            >{{ data.value }}</span>
          </template>

          <template #cell(createdAt)="data">
            <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
          </template>

          <template #cell(order_status)="data">
            <span class="text-nowrap">{{ data.value || 'Đang xử lý' }}</span>
          </template>

          <template #cell(updatedAt)="data">
            <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
          </template>

          <template #cell(detail.inputAmount)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
          </template>

          <template #cell(detail.realAmount)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(detail.forControl)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(fee)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(detail.realReceiveAmount)="data">
            <span class="text-nowrap">{{ data.value.toLocaleString() || 0 }}</span>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="mapVariant(data.value)">
              {{ mapStatus(data.value) }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="ArrowRightCircleIcon"
                    size="20"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <span @click="callbackCustomer(data.item)">Callback khách hàng</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <span @click="viewImageResult(data.item)">xem ảnh kết quả</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>

        <b-row>
          <b-col class="d-flex justify-content-md-end">
            <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-text="Trang đầu"
              prev-text="Trang trước"
              next-text="Trang tiếp"
              last-text="Trang cuối"
              @change="changePage"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <payout-detail-modal
      ref="payoutDetailModal"
      @reload="fetchOrderPayout"
    />

  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BPagination,
  BFormDatepicker,
  BFormGroup,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BAlert,
  BOverlay,
  BSpinner,
  BCard,
  BFormTimepicker,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { watch, onMounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import dateFormat from 'dateformat'
// eslint-disable-next-line import/no-cycle
import { useRouter, formatDateTimeDb } from '@core/utils/utils'
import PayoutDetailModal from '@/views/order-management/PayoutDetailModal.vue'
import Swal from 'sweetalert2'
import {
  useMasterFilter,
  useOderPayInActions,
  useOrderPayOutUi,
  useOderPayOutFilter,
} from './useOrder'

export default {
  name: 'Payout',
  components: {
    PayoutDetailModal,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BPagination,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BAlert,
    BOverlay,
    BSpinner,
    BCard,
    vSelect,
    StatisticCardHorizontal,
    BFormTimepicker,
  },
  setup() {
    const { router } = useRouter()
    const payoutDetailModal = ref(null)
    const isLoading = ref(false)
    const isTableLoading = ref(false)

    const timeFrom = ref(null)
    const timeTo = ref(null)

    const parseDateTime = (date, time, type) => {
      let timeParse
      let second = 0
      if (!date) return null
      if (type === 'from') {
        timeParse = time || '00:00'
      } else {
        timeParse = time || '23:59'
        second = 59
      }
      const dateArr = date.split('-')
      const timeArr = timeParse.split(':')
      return dateFormat(new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], second), 'yyyy-mm-dd HH:MM:ss')
    }

    const {
      itemView, itemViewOptions,
    } = useOrderPayOutUi()

    const {
      fields,
      items,
      pagination,
      searchKeyWord,
      fetchPayouts,
      mapStatus,
      mapVariant,
    } = useOderPayOutFilter()

    const {
      dateFrom,
      dateTo,
      merchantName,
      payGate,
      orderStatus,
      merchantNameOptions,
      payGateOptions,
      orderStatusOptions,
      statistic,
      fetchMerchantNameOptions,
      fetchPayGateOptions,
      fetchStatistic,
    } = useMasterFilter()

    const {
      handleCallbackCustomer,
      handleChangeStatus,
    } = useOderPayInActions()

    const fetchOrderPayout = (isSearch = false) => {
      isTableLoading.value = true
      fetchPayouts({
        searchKeyWord: searchKeyWord.value,
        page: isSearch ? 1 : pagination.value.current_page,
        dateFrom: parseDateTime(dateFrom.value, timeFrom.value, 'from'),
        dateTo: parseDateTime(dateTo.value, timeTo.value, 'to'),
        merchantId: merchantName.value,
        payGateId: payGate.value,
        status: orderStatus.value,
      }).then(res => {
        const { data, meta } = res
        items.value = data
        pagination.value = meta.pagination
        isTableLoading.value = false
      })
    }

    const fetchOrderPayInStatistic = () => {
      fetchStatistic({ type: 'payout' }).then(res => {
        const { data } = res
        statistic.value = data
      })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchOrderPayout()
    }

    const callbackCustomer = order => {
      isLoading.value = true
      handleCallbackCustomer({ id: order.realId }).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Thành công',
          text: 'Đã callback lại khách hàng',
        })
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Thất bại',
          text: 'callback khách hàng thất bại',
        })
      }).finally(() => {
        isLoading.value = false
      })
    }

    const viewImageResult = () => {
      Swal.fire({
        icon: 'error',
        title: 'Not found',
        text: 'Giao dịch không có ảnh kết quả',
      })
    }

    onMounted(() => {
      fetchOrderPayout()
      fetchMerchantNameOptions()
      fetchPayGateOptions()
      fetchOrderPayInStatistic()
    })

    watch([itemView], change => {
      if (change[0] === 'pay-out') {
        router.push('/order-management/pay-out')
      } else {
        router.push('/order-management/pay-in')
      }
    }, {
      deep: true,
    })

    return {
      itemView,
      itemViewOptions,
      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      payGate,
      payGateOptions,
      orderStatus,
      orderStatusOptions,
      timeFrom,
      timeTo,

      fields,
      items,
      statistic,
      pagination,
      searchKeyWord,
      isLoading,
      isTableLoading,

      fetchOrderPayout,
      formatDateTimeDb,
      mapStatus,
      payoutDetailModal,
      changePage,
      mapVariant,
      callbackCustomer,
      viewImageResult,
      parseDateTime,
    }
  },
  methods: {
    exportExcel() {
      if (!this.isTimeVerify()) {
        this.$swal(this.$t('Thông báo'), this.$t('Chưa chọn thời gian hoặc khoảng thời gian lớn hơn 30 ngày'), 'warning')
        return
      }
      this.isLoading = true
      this.$http.get('/export/order', {
        responseType: 'blob',
        params: {
          q: this.searchKeyWord,
          orderBy: 'created_at',
          sortedBy: 'desc',
          dateFrom: this.parseDateTime(this.dateFrom, this.timeFrom, 'from'),
          dateTo: this.parseDateTime(this.dateTo, this.timeTo, 'to'),
          merchantId: this.merchantName,
          payGateId: this.payGate,
          status: this.orderStatus,
          limit: 100,
          page: 1,
        },
        headers: {
          lang: 'vi',
        },
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xls' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'order-export.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false
        })
    },
    isTimeVerify() {
      if (this.dateFrom && this.dateTo) {
        const dateFrom = new Date(this.dateFrom)
        const dateTo = new Date(this.dateTo)
        const diffTime = Math.abs(dateTo - dateFrom)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays <= 30
      }
      return false
    },
  },
}
</script>

<style scoped>
.transaction-item {
  color: #3A57E8;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
