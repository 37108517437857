<template>
  <b-modal
    ref="payoutDetailModal"
    centered
    size="lg"
    title="Chi tiết giao dịch"
    :hide-footer="true"
  >
    <b-row cols="2">
      <b-col cols="6">
        <p class="payin-item">
          <span class="payin-item-title">Mã giao dịch:</span>
          <span class="payin-item-value">{{ payoutDetail.transactionId }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Merchant:</span>
          <span class="payin-item-value">{{ payoutDetail.merchantName }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Trạng thái giao dịch:</span>
          <span class="payin-item-value">{{ mapStatus(payoutDetail.status) }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Tên ngân hàng nhận:</span>
          <span class="payin-item-value">{{ payoutDetail.receiver }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Số tài khoản nhận:</span>
          <span class="payin-item-value">{{ payoutDetail.detail.receiverNumber }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Tên người nhận:</span>
          <span class="payin-item-value">{{ payoutDetail.detail.receiverName }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Số tiền:</span>
          <span class="payin-item-value">{{ payoutDetail.detail.amount.toLocaleString() }}</span>
        </p>
      </b-col>

      <b-col cols="6">
        <b-img
          :src="payoutDetail.detail.qrCode"
          fluid
          rounded
          alt="QR"
          class="p-4 bg-dark"
        />
      </b-col>
    </b-row>

    <b-row class="pt-5">
      <b-col class="d-flex justify-content-center">
        <b-button
          variant="outline-danger"
          class="mr-2"
          :disabled="payoutDetail.status !== 'pending' || isBusy"
          @click="handleReject(payoutDetail)"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          Từ chối
        </b-button>
        <b-button
          variant="success"
          class="ml-2"
          :disabled="payoutDetail.status !== 'pending' || isBusy"
          @click="handleSuccess(payoutDetail)"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          Đã chuyển
        </b-button>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import { formatDateTimeDb } from '@/libs/timezone'
import {
  BModal,
  BRow,
  BCol,
  BImg,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useOderPayInFilter, useOrderPayoutActions } from '@/views/order-management/useOrder'
import Swal from 'sweetalert2'

export default {
  name: 'PayoutDetailModal',
  components: {
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BSpinner,
  },
  setup(props, { emit }) {
    const isBusy = ref(false)
    const payoutDetailModal = ref(false)
    const payoutDetail = ref({
      realId: '',
      transactionId: '',
      merchantName: '',
      receiver: null,
      amountChange: 0,
      status: 'pending',
      fee: null,
      detail: {
        qrCode: null,
        receiverName: '',
        sourceAccountId: '',
        amount: 0,
        receiverNumber: '',
      },
      createdAt: '',
      updatedAt: '',
    })

    const {
      mapStatus,
    } = useOderPayInFilter()

    const {
      changeOrderStatus,
    } = useOrderPayoutActions()

    const handleSuccess = order => {
      isBusy.value = true
      changeOrderStatus({
        orderId: order.realId,
        code: 'success',
        message: 'Thanh toán thành công',
      }).then(() => {
        payoutDetailModal.value.hide()
        Swal.fire(
          'Thành công!',
          'xác nhận chuyển tiền thành công!',
          'success',
        )
      }).catch(() => {
        Swal.fire(
          'Thất bại!',
          'xác nhận chuyển tiền thất bại!',
          'error',
        )
      }).finally(() => {
        isBusy.value = false
        emit('reload')
      })
    }
    const handleReject = order => {
      isBusy.value = true
      changeOrderStatus({
        orderId: order.realId,
        code: 'fail',
        message: 'Thanh toán thất bại',
      }).then(() => {
        payoutDetailModal.value.hide()
        Swal.fire(
          'Thành công!',
          'Huỷ yêu cầu thành công!',
          'success',
        )
      }).catch(() => {
        Swal.fire(
          'Thất bại!',
          'Huỷ yêu cầu thất bại!',
          'error',
        )
      }).finally(() => {
        emit('reload')
        isBusy.value = false
      })
    }

    const open = payout => {
      payoutDetail.value = payout
      payoutDetailModal.value.show()
    }

    return {
      isBusy,
      payoutDetailModal,
      payoutDetail,
      formatDateTimeDb,
      mapStatus,
      open,
      handleSuccess,
      handleReject,
    }
  },
}
</script>

<style scoped>
.payin-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 70px */

  color: #000000;
}
.payin-item {
  display: flex;
  align-items: center;
}

.payin-item .payin-item-value {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  padding-left: 10px;
  /* or 70px */

  color: #000000;
}
</style>
